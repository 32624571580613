<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <!-- 담당부서 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
                class="mr-2"
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 위임인 -->
            <v-col cols="12" xs="12">
              <!-- <span class="mx-1 text-h6 primary--text">{{ clientName }}</span> -->
              <v-text-field
                v-model="clientName"
                label="위임인"
                prepend-icon="mdi-account-circle"
                readonly
                color="primary"
                class="text-input-font-color__primary"
              ></v-text-field>
            </v-col>
            <!-- 계약번호 -->
            <v-col cols="12" xs="12" sm="6">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:230px">
                  <v-text-field
                    ref="txtContractNum"
                    v-model.trim="form.gubun2"
                    label="계약번호"
                    placeholder="조직내부계약번호"
                    maxlength="30"
                    :error="isOverlabed"
                  ></v-text-field>
                </div>
              </v-row>
            </v-col>
            <!-- 수임일 -->
            <v-col cols="12" xs="12" sm="6" class="pl-5">
              <v-text-field
                v-model.trim="form.date1"
                label="수임일"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </v-col>
            <!-- 착수금,상공보수,시간보수,비용청구 -->
            <v-col cols="12" xs="12">
              <v-card
                elevation="0"
                outlined
                tile
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-toolbar
                      flat
                      dense
                    >
                      <v-toolbar-title class="text-subtitle-1">
                        계약금액 <span class="primary--text font-weight-bold">{{ numberFormat(totalSum1) }}</span> 원
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-title class="text-subtitle-1">
                        수금대상 <span class="primary--text font-weight-bold">{{ numberFormat(totalSum2) }}</span> 원
                      </v-toolbar-title>
                    </v-toolbar>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="3">
                    <v-card
                      :elevation="elevation2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      착수금
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-card
                      :elevation="elevation2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      성공보수
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-card
                      :elevation="elevation2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      시간보수
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-card
                      :elevation="elevation2"
                      tile color="grey lighten-2"
                      class="pa-1 text-center"
                    >
                      비용청구
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                elevation="0"
                outlined
                tile
                min-height="70"
                max-height="240"
                class="overflow-y-auto ma-0 pa-0"
              >
                <template
                  v-for="(item, n) in amounts"
                >
                  <v-row
                    :key="n"
                    no-gutters
                    class="ma-0 pa-0"
                  >
                    <v-col v-show="n >= 1" cols="12" sm="12">
                      <v-divider></v-divider>
                    </v-col>
                    <!-- 중요:등록 컴포넌트 -->
                    <input-case-component
                      :amts="item"
                      :timeSheets="timeSheets"
                      :costs="costs"
                      :timeSheetSum="timeSheetSum"
                      :timeMinutes="timeMinutes"
                      :costSum="costSum"
                      :pdfTitle="pdfTitle"
                    ></input-case-component>
                  </v-row>
                </template>
              </v-card>
            </v-col>
            <!-- 특약사항 -->
            <v-col cols="12" xs="12">
              <v-textarea
                ref="refStr1"
                v-model="form.str1"
                outlined
                label="특약사항"
                placeholder="입력하세요"
                no-resize
                rows="3"
                counter="200"
                :rules="[rules.txtCounter]"
                @update:error="chkValidate = false"
                class="mt-5"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ viewParentInfo }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
import isNumber from '@/filters/isNumber'
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat3 from '@/filters/strDateFormat3'
import isSpKey from '@/filters/isSpecialCharacter'

// 구분: 개별사건계약 등록 컴포넌트
import inputCaseComponent from '@/components/suit/inputCaseComponent'

export default {
  components: {
    baseModal,
    inputCaseComponent
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 25, // 유형(계약) - 중요: 개별사건계약
      depth: 2,
      pId: 0,
      pType: 1, // 소송(1), 자문(2)
      pInfo: '', // !! 부모(소송/자문)의 객체를 JSON 문자열로 만든것
      rId: 0,
      rType: 3, // 인명부 - 의뢰인
      rInfo: '', // !! 의뢰인 객체를 JSON 문자열로 만든것
      isStar: 0,
      isSchedule: 0, // !! 계약은 일정표시 안됨
      subtype: 1, // 1로 세팅 - 중요: 여기선 개별사건계약임 2는 고문계약
      gubun1: '개별사건계약', // !! 계약구분 픽스함
      gubun2: '', // !! 계약번호 - 계약에만 있다(전체계약과 비교해서 중복제거)
      status: 1, // !! 계약상태 - 유효=1 로 픽스
      date1: '', // 수임일
      str1: '', // 수임내용
      str2: '0', // 수금대상(금액)
      str3: '0', // 계약금액(금액)
      txt1: '[]', // !! 착수금,성공보수,시간보수,비용청구 - mediumtext에 json 타입으로 들어간다
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // 구분: 연계된 의뢰인 이름 [2021.5.12]
    clientName: '',
    // 구분: 입력되는 착수금, 성공보수, 시간보수, 비용청구
    amounts: [
      // { amt1: 0, amt2: 0, amt3: 0, amt4: 0 } // !!주의: 초기값을 주면 안되네?
    ],
    // 구분: [2021.5.18] 새로 추가된 시간보수합과 비용합
    timeSheets: [],
    costs: [],
    timeSheetSum: 0,
    timeMinutes: '',
    costSum: 0,
    pdfTitle: '',
    // 구분:
    elevation4: 0,
    elevation2: 2,
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str2: '수금대상' // 계약은 필수값이 없구나
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: 내용(str1) 유효성 체크
    chkValidate: true,
    // 구분: 계약번호 중복체크용 변수들
    mustChk: false, // 중복체크를 해야 하는지(계약번호에 입력된 경우)
    chkMulti: false, // 참고: 중복 확인했는지 여부
    isOverlabed: false, // 참고: 중복 되었는지 여부
    // 구분: 일자 - 시간
    date1Selector: false, // 수임일 셀렉터
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 바로가기에 보여줄 부모 정보
    viewParentInfo: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
  },

  computed: {
    totalSum1 () { // 총계약금액 - 착수금+성공보수+시간보수
      let rv = 0
      for (const item of this.amounts) {
        rv += item.amt1 + item.amt2 + item.amt3
      }
      return rv
    },
    totalSum2 () { // 총수금대상 - 계약금액+비용
      let rv = 0
      for (const item of this.amounts) {
        rv += item.amt1 + item.amt2 + item.amt3 + item.amt4
      }
      return rv
    }
  },

  methods: {
    isSpKey, // 특수문자 필터
    isNumber, // 숫자만 인지 체크하는 필터
    strToNumber, // '10,000' => 10000 정수로 변환
    numberFormat,
    numberFormatR,
    preventComma,
    strDateFormat,
    strDateFormat3,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 1 // 부모 타입: 소송(1), 자문(2)
      this.form.pInfo = '' // 정보위치(부모의 정보 중 보여줄 정보 이름)
      this.form.rId = 0
      this.form.rType = 3 // 업무대상자는 인명부(3)의 의뢰인
      this.form.rInfo = ''
      this.form.isStar = 0

      this.form.gubun1 = '개별사건계약' // !! 계약구분 픽스
      this.form.gubun2 = '' // !! 계약번호 - 계약에만 있다(전체계약과 비교해서 중복제거)

      // !! 일자 초기화 - clone() 으로 복사해서 써야한다!
      const curDate = this.$moment() // 오늘
      this.form.date1 = curDate.format('YYYY-MM-DD') // 수임일 초기값(오늘)

      this.form.str1 = '' // 내용
      this.form.str2 = '0' // 수금대상(금액)
      this.form.str3 = '0' // 계약금액(금액)

      this.form.parentInfo = '' // 부모정보(소송/자문 사건번호나 관리번호)

      this.index = -1 // 수정용 인덱스 초기화

      // 중요:등록금액 초기화 - 주의: 여기서 초기화 하면 안된다.
      // this.amounts = [
      //   { amt1: 0, amt2: 0, amt3: 0, amt4: 0 }
      // ]
      // this.form.txt1 = '[]' // !!등록금액: 착수금,,,

      // !![2021.5.18]새로 추가된 시간보수합과 비용합산
      this.timeSheets = []
      this.costs = []
      this.timeSheetSum = 0
      this.timeMinutes = ''
      this.costSum = 0
      this.pdfTitle = ''

      // 중요: 폼값 뿐만이 아니라 중복체크와 상태값에 따른 초기화도 같이해야 한다.
      // !! 중복체크 초기화
      this.mustChk = false // 계약번호 중복체크를 해야 하는지 여부
      this.chkMulti = false // 계약번호 중복 확인했는지 여부
      this.isOverlabed = false // 계약번호 중복 되었는지 여부
    },
    // !![2021.5.10] 마지막 파라미터로 의뢰인객체가 들어간다
    async open (title, options, index = -1, obj = {}, client, subInfo) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(this.article) // !! props 로 넘어온 부모 데이터
        // console.log(client) // open() 에서 넘어온 의뢰인 객체
        // console.log(subInfo)

        // 초기화
        await this.initDialog()

        // !![2021.5.12] 의뢰인명 매칭
        this.clientName = client.name

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id
        this.form.pType = this.article.type1

        // !! 소송/자문에 따른 부모 정보 - 2021.3.19 수정
        if (this.article.type1 === 1) { // 소송인 경우
          this.viewParentInfo = `${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
          this.form.parentInfo = this.article.caseNum
        } else { // 자문
          this.viewParentInfo = `${this.article.manageNum}`
          this.form.parentInfo = this.article.manageNum
        }

        // 중요:[2021.3.19] 부모정보(소송/자문)의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo)

        // !!중요:[2021.5.10] 넘어온 의뢰인 정보를 rId, rInfo에 매칭시킨다!
        this.form.rId = client.id
        this.form.rInfo = JSON.stringify(client) // 의뢰인 객체를 JSON 문자열로 저장

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // !!중요: 등록값 복구
          this.amounts = JSON.parse(obj.txt1)

          this.index = index

          this.form.id = obj.id
          this.form.isStar = obj.isStar
          this.form.gubun2 = obj.gubun2 // 계약번호 - !! 여기서 watch 가 발동될것임

          // 일자 처리
          const curDate = this.$moment() // 오늘
          this.form.date1 = (obj.date1) ? obj.date1.substr(0, 10) : curDate.format('YYYY-MM-DD') // 수임일

          // !! 착수금..등 금액 리스트
          this.form.txt1 = obj.txt1

          this.form.str1 = obj.str1 // 내용
          this.form.str2 = obj.str2 // 수금대상(금액)
          this.form.str3 = obj.str3 // 계약금액(금액)

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용

          // !! 관리번호가 등록되어있는 경우 - 시간상 지연을 주기위해 nextTick()을 사용해야 한다!
          this.$nextTick(function () {
            if (obj.gubun2) {
              this.mustChk = false // 중복체크 필요없다
              this.chkMulti = true // 중복체크를 했다
              this.isOverlabed = false // 중복되지 않았다
            } else {
              this.mustChk = false
              this.chkMulti = false
              this.isOverlabed = false
            }
          })
        } else {
          // !! 등록시 0으로 초기화 해야한다
          this.amounts = [
            { amt1: 0, amt2: 0, amt3: 0, amt4: 0 }
          ]
        }

        // !![2021.5.18] 부가정보로 넘어온 시간보수 합과 비용합산
        this.timeSheets = subInfo.timeSheets // 넘어온 타임시트 배열
        this.costs = subInfo.costs // 넘어온 비용 배열
        this.timeSheetSum = subInfo.timeSheetSum
        this.timeMinutes = subInfo.timeMinutes // 넘어온 시간:분 합산
        this.costSum = subInfo.costSum
        this.pdfTitle = subInfo.pdfTitle

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인
    async agree () {
      try {
        //
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 인명부 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      if (this.article.type1 === 1) { // 수금인 경우
        // !! 현재 경로가 case 디렉토리가 아닌 경우만 case/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('case') !== -1)) {
          this.$router.push(`/case/${this.article.id}`)
        }
      } else { // 자문일때
        // !! 현재 경로가 advice 디렉토리가 아닌 경우만 advice/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('advice') !== -1)) {
          this.$router.push(`/advice/${this.article.id}`)
        }
      }
    }
  }
}
</script>

<style>
.text-input-font-color__primary .v-text-field__slot input {
  color: #1976D2 !important;
}
</style>
